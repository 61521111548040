
import { userData } from './signals';
import { Router } from 'riza';
import { logout, navigate } from './actions';

import Extractors from './panels/extractors/main';
import Service from './panels/service/main';
import Profile from './panels/profile/main';

function init() {
    Router.refresh();
    navigate(Router.initialLocation);
}

export default () =>
    <r-panel class="s-fill f-row" onRootReady={ init }>

        <div class="sidebar s-static">
            <img src="img/logo-sidebar.png" />

            <div class="r-tabs">

            <b>Service</b>
                <div data-priv="admin|client|user">
                    <r-panel data-route="/extractors/">
                    <a href="#/extractors/list/">
                        <i class="fa-solid fa-bolt"></i>
                        <span>Data Extractors</span>
                    </a>
                    </r-panel>
                </div>

            <b data-priv="admin|client">Configuration</b>

                <r-panel data-priv="admin|client" data-route="/service/users/">
                    <a href="#/service/users/list/">
                        <i class="fa-solid fa-users"></i>
                        <span>Users</span>
                    </a>
                </r-panel>

                <r-panel data-priv="admin" data-route="/service/clients/">
                    <a href="#/service/clients/list/">
                        <i class="fa-solid fa-user-tag"></i>
                        <span>Clients</span>
                    </a>
                </r-panel>

                <r-panel data-priv="admin|client" data-route="/service/instances/">
                    <a href="#/service/instances/list/">
                        <i class="fa-solid fa-server"></i>
                        <span>Service Instances</span>
                    </a>
                </r-panel>

                <r-panel data-priv="admin|client" data-route="/service/tokens/">
                    <a href="#/service/tokens/list/">
                        <i class="fa-solid fa-key"></i>
                        <span>API Access Tokens</span>
                    </a>
                </r-panel>

                <r-panel data-priv="admin" data-route="/service/plans/">
                    <a href="#/service/plans/list/">
                        <i class="fa-solid fa-star-of-life"></i>
                        <span>Billing Plans</span>
                    </a>
                </r-panel>

            <b>Account</b>
                <div>
                    <r-panel data-route="/profile/">
                    <a href="#/profile/details/">
                        <i class="fas fa-user"></i>
                        <span>Your Profile</span>
                    </a>
                    </r-panel>
                </div>

                <div>
                    <r-panel data-route="/logout/">
                    <a href="#/logout/" onClick={ logout }>
                        <i class="fas fa-arrow-right-from-bracket"></i>
                        <span>Sign out</span>
                    </a>
                    </r-panel>
                </div>

            </div>
        </div>

        <div className="content s-fill f-row">

            <Extractors />
            <Service />
            <Profile />

        </div>

    </r-panel>
;
