
import List from './list';
import Add from './add';
import Update from './update';
import Configure from './configure';

export default () =>
    <r-panel class="f-col s-fill p-6" data-route="/extractors/">

        <List />
        <Add />
        <Update />
        <Configure />

    </r-panel>
;
