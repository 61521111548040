
import Users from './users/main.jsx';
import Clients from './clients/main.jsx';
import Instances from './instances/main.jsx';
import Tokens from './tokens/main.jsx';
import Plans from './plans/main.jsx';

export default () =>
    <r-panel class="f-col s-fill" data-route="/service/" data-priv="admin|client">

        <div class="s-fill p-rel">
        <div class="p-fill p-6 ovf-auto">

            <Users/>
            <Clients/>
            <Instances/>
            <Tokens />
            <Plans/>

        </div>
        </div>

    </r-panel>
;
