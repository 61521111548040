
import { DataSource } from 'riza';
import { ds as dsInstances } from '../service/instances/common';

export const ds = new DataSource('extractors');

/* ****************** */
export const Fields = ({ updating=false }) =>
<div>

    <div className="field">
        <label>Instance</label>
        <r-select dataList={ dsInstances.enum } data-field="instance_id" />
    </div>

    <div className="field">
        <label>Extractor Name</label>
        <input type="text" data-field="name" />
    </div>

</div>;
