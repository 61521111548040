
import { clearTableFilters, deleteRecord, navigate } from '../../../actions';
import { Pagination } from '../../../elems';
import { ds } from './common';

/* ****************** */
export default () => 
    <r-panel class="s-fill" data-route="/service/instances/list/" onPanelShown={ () => ds.refresh() }>

        <h1>Service Instances</h1>

        <Pagination dataSource={ ds }>
            <span class="btn alt-4" onClick={ () => navigate("/service/instances/add/") }>
                <i class="fa-solid fa-plus me-1 me-0-d"></i> <span class="d-none-d">Create Instance</span>
            </span>
        </Pagination>

        <div data-priv="admin" class="d-block">
            <r-table dataSource={ ds }>
            <table>
                <thead>
                    <tr>
                        <th style:width="9rem" data-sort="created"><span>Created</span></th>
                        <th data-sort="name"><span>Instance Name</span></th>
                        <th data-sort="client"><span>Client</span></th>
                        <th data-sort="plan"><span>Plan</span></th>
                        <th><span>Price per Document</span></th>
                        <th><span>Minimum Documents</span></th>
                        <th><span>Current Usage</span></th>
                        <th style:width="3rem"></th>
                    </tr>
                </thead>

                <thead>
                    <tr>
                        <th></th>
                        <th><input class="input-small" type="text" data-property="filter_name" /></th>
                        <th><input class="input-small" type="text" data-property="filter_client" /></th>
                        <th><input class="input-small" type="text" data-property="filter_plan" /></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style:textAlign="center">
                            <span class="btn small" onClick={ clearTableFilters }>
                                <i class="fas fa-filter-circle-xmark"></i>
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody className="x-empty">
                    <tr>
                        <td colSpan="8">There are no instances to show.</td>
                    </tr>
                </tbody>

                <tbody class="x-data" content={ (item) =>
                    <tr class="is-clickable no-underline" onClick={ () => navigate("/service/instances/update/"+item.id) }>
                        <td>{item.s_created}</td>
                        <td>
                            { item.is_enabled ? <i class="fas fa-check me-1 text-green"></i> : <i class="fas fa-times me-1 text-red"></i> }
                            { item.name }
                        </td>
                        <td>{item.client}</td>
                        <td>{item.plan}</td>
                        <td>{parseFloat(item.billing_document_price) ? 'US$ ' + item.billing_document_price : '-'}</td>
                        <td>{item.billing_document_min ? item.billing_document_min : '-'}</td>
                        <td>{item.current_usage ? 'US$ ' + (parseFloat(item.billing_document_price) * item.current_usage).toFixed(3) : '-'}</td>
                        <td></td>
                    </tr>
                }>
                </tbody>
            </table>
            </r-table>
        </div>

        <div data-priv="client" class="d-block">
            <r-table dataSource={ ds }>
            <table>
                <thead>
                    <tr>
                        <th style:width="9rem" data-sort="created"><span>Created</span></th>
                        <th data-sort="name"><span>Instance Name</span></th>
                        <th data-sort="plan"><span>Plan</span></th>
                        <th><span>Price per Document</span></th>
                        <th><span>Minimum Documents</span></th>
                        <th><span>Current Usage</span></th>
                        <th style:width="3rem"></th>
                    </tr>
                </thead>

                <thead>
                    <tr>
                        <th></th>
                        <th><input class="input-small" type="text" data-property="filter_name" /></th>
                        <th><input class="input-small" type="text" data-property="filter_plan" /></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style:textAlign="center">
                            <span class="btn small" onClick={ clearTableFilters }>
                                <i class="fas fa-filter-circle-xmark"></i>
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody className="x-empty">
                    <tr>
                        <td colSpan="7">There are no instances to show.</td>
                    </tr>
                </tbody>

                <tbody class="x-data" content={ (item) =>
                    <tr class="is-clickable no-underline" onClick={ () => navigate("/service/instances/update/"+item.id) }>
                        <td>{item.s_created}</td>
                        <td>
                            { item.is_enabled ? <i class="fas fa-check me-1 text-green"></i> : <i class="fas fa-times me-1 text-red"></i> }
                            { item.name }
                        </td>
                        <td>{item.plan}</td>
                        <td>{parseFloat(item.billing_document_price) ? 'US$ ' + item.billing_document_price : '-'}</td>
                        <td>{item.billing_document_min ? item.billing_document_min : '-'}</td>
                        <td>{item.current_usage ? 'US$ ' + (parseFloat(item.billing_document_price) * item.current_usage).toFixed(3) : '-'}</td>
                        <td></td>
                    </tr>
                }>
                </tbody>
            </table>
            </r-table>
        </div>

    </r-panel>
;
