
import List from './list';
import Add from './add';
import Update from './update';
import Instances from './instances';

export default () =>
    <r-panel data-route="/service/users/">

        <List />
        <Add />
        <Update />
        <Instances />

    </r-panel>
;
