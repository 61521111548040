
import List from './list';
import Add from './add';
import Update from './update';

export default () =>
    <r-panel data-route="/service/clients/">

        <List />
        <Add />
        <Update />

    </r-panel>
;
