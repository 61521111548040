
import { deleteRecord, loadForm, goBack } from '../../actions';
import { Fields, ds } from './common';
import { signal, watch, expr } from 'riza';

const fields = signal([]);
const files = signal([]);
const output = signal([]);
let extractorId;
let extractorData;

function FieldModel(data=null) {
    return {
        name: signal(data ? data.name : ''),
        description: signal(data ? data.description : ''),
        type: signal(data ? data.type : 'text'),
    }
}

function newField() {
    fields.value.push(FieldModel());
    fields.notify();
}

function rmField(idx) {
    if (fields.value.length == 1) return;
    fields.value.splice(idx, 1);
    fields.notify();
}

function clearFields() {
    fields.set([]);
    newField();
}

function deleteSample (id) {
    ds.fetchData({ f: '.samples.delete', id });
}

function clearFiles() {
    files.value.forEach((f) => {
        deleteSample(f.id);
    });
    files.set([]);
}

function rmFile(idx) {
    deleteSample(files.value[idx].id);
    files.value.splice(idx, 1);
    files.notify();
}

function viewFile(id) {
    window.open(ds.makeUrl({ f: '.samples.text', id }), '_blank');
}

function init ({ id })
{
    extractorId = id;
    output.set([]);

    ds.fetchData({ f: '.load', id }).then(r => {
        if (r.response != 200) return goBack();
        extractorData = r.data;
        fields.set((r.data.fields ?? []).map(f => FieldModel(f)));
    });

    ds.fetchData({ f: '.samples.list' }).then(r => {
        if (r.response != 200) return goBack();
        files.set(r.data);
    });
}

function formatSize(value) {
    value = ~~value;
    if (value < 1024) return value + ' Bytes';
    if (value < 1024 * 1024) return (value / 1024).toFixed(1) + ' KB';
    return (value / 1024 / 1024).toFixed(1) + ' MB';
}

function showFilePicker (allowMultiple, accept, callback)
{
    let input = document.createElement("input");

    input.type = "file";
    input.accept = accept;
    input.style.display = 'none';
    input.multiple = allowMultiple;

    document.body.appendChild(input);

    input.onchange = () => {
        callback(input.files);
    };

    document.body.onfocus = () => {
        document.body.onfocus = null;
        document.body.removeChild(input);
    };

    input.click();
}

function loadAsDataURL (file, callback)
{
    let reader = new FileReader();
    reader.onload = (e) => {
        callback (e.target.result);
    };
    reader.readAsDataURL(file);
}

function loadAllAsDataURL (fileList, callback)
{
    let result = [];

    if (!fileList || !fileList.length) {
        callback(result);
        return;
    }

    let loadNext = (i) =>
    {
        if (i == fileList.length) {
            callback(result);
            return;
        }

        loadAsDataURL (fileList[i], (url) => {
            result.push({ name: fileList[i].name, size: fileList[i].size, url: url });
            loadNext(i+1);
        });
    };

    loadNext(0);
}

function loadFiles () {
    showFilePicker(true, '.png, .jpg, .pdf, .txt', (selectedFiles) => {
        loadAllAsDataURL(selectedFiles, (loadedFiles) => {
            loadedFiles.forEach((file) =>
            {
                let data = file.url.split(',')[1];
                ds.fetchData({ f: '.samples.add', name: file.name, size: file.size, data }).then(r => {
                    if (r.response != 200) return alert(r.error);
                    file.id = r.id;
                    files.value.push(file);
                    files.notify();
                });
            });
        });
    });
}

function saveFields()
{
    extractorData.fields = fields.value.map(f => ({
        name: f.name.value,
        description: f.description.value,
        type: f.type.value
    }));

    ds.fetchData({ f: '.save', id: extractorId, data: btoa(JSON.stringify(extractorData)) }).then(r => {
        if (r.response != 200)
            alert(r.error);
        else
            alert('Changes have been saved.');
    });
}

function clearOutput() {
    output.set([]);
}

async function testExtractor()
{
    extractorData.fields = fields.value.map(f => ({
        name: f.name.value,
        description: f.description.value,
        type: f.type.value
    }));

    let data = btoa(JSON.stringify(extractorData));
    for (let f of files.value) {
        let r = await ds.fetchData({ f: '.test', id: extractorId, data, sample: f.id });
        output.value.push({ response: 100, name: f.name });
        output.value.push(r);
        output.notify();
    }
}

/* *********** */
const Field = ({ model, idx }) =>
<div class="field-block">
    <span class="title">{1 + idx}</span>
    <div class="f-row g-2">
        <div className="field s-8">
            <label>Variable Name</label>
            <input type="text" trait:valueSignal={model.name} />
        </div>
        <div className="field s-4">
            <label>Data Type</label>
            <select trait:valueSignal={model.type} >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="datetime">Date and Time</option>
                <option value="date">Date</option>
                <option value="time">Time</option>
            </select>
        </div>
    </div>

    <div className="field" style="padding-bottom: 0; margin-bottom: 0;">
        <label>AI Hint</label>
        <input type="text" trait:valueSignal={model.description} />
    </div>

    <span class="btn small alt-2 left-1" onClick={ () => rmField(idx) }>
        <i class="fa fa-times"></i>
    </span>
</div>
;

/* *********** */
const FileInfo = ({ model, idx }) =>
<div class="file-block">
    <span class="btn small alt-2 left-1" onClick={ () => rmFile(idx) }>
        <i class="fa fa-times"></i>
    </span>
    <span class="btn small alt-4" onClick={ () => viewFile(model.id) }>
        <i class="fa fa-eye"></i>
    </span>
    <em>{model.name}</em>
    <b>{formatSize(model.size)}</b>
</div>
;

// *********************************************
export default () => 
    <r-panel class="s-fill f-col" data-route="/extractors/configure/:id" onPanelShown={ init }>

        <h1>Data Extractors</h1>

        <div className="buttons">
            <div class="button-container me-2">
            <div class="button-group">
                <span class="btn" onClick={ goBack }>
                    <i class="fa-solid fa-arrow-left-long me-1"></i>
                    <span class="d-none-d">Go Back</span>
                </span>
            </div>
            </div>
        </div>

        <div className="s-fill f-row g-4">

            <div class="f-col s-even">
                <div className="form auto s-fill f-col">
                <h3>Field Configuration</h3>

                <div class="text-center mb-4">
                    <span class="btn alt-4" onClick={ newField }>
                        <i class="fa fa-plus me-1"></i>
                        <span>Add Field</span>
                    </span>

                    <span class="btn" onClick={ saveFields }>
                        <i class="fa fa-save me-1"></i>
                        <span>Save Changes</span>
                    </span>

                    <span class="btn" onClick={ clearFields }>
                        <i class="fa fa-trash me-1"></i>
                        <span>Clear</span>
                    </span>
                </div>

                <div class="s-fill p-rel ovf-hidden">
                <div class="p-fill ovf-auto">
                    { $fields.map((model, idx) => <Field model={model} idx={idx} />) }
                    <br/>
                </div>
                </div>
                </div>
            </div>

            <div class="f-col s-even">
                <div className="form auto s-fill f-col">
                    <h3>Output Test</h3>

                    <div class="mb-4">
                        <span class="btn alt-4 spinner" onClick={ testExtractor }>
                            <i class="fa fa-bolt me-1"></i>
                            <span>Test Extractor</span>
                        </span>

                        <span class="btn" onClick={ clearOutput }>
                            <i class="fa-solid fa-broom me-1"></i>
                            <span>Clear Output Log</span>
                        </span>
                    </div>

                    <div class="s-fill p-rel ovf-hidden">
                    <div class="p-fill ovf-auto">
                        { $output.map((r) =>
                            r.response == 100 ? <div class="res file"><b>Source:</b> {r.name}</div> :
                            r.response != 200   ? <div class="res err">ERROR: {r.error}</div>
                                                : <div class="res lines">{Object.entries(r.fields).map(line => <div>
                                                    <b>{line[0]}</b>
                                                    <em>{line[1]}</em>
                                                </div>)}</div>
                        ) }
                        { $output.length == 0 ? <div class="message info">There are no messages to show.</div> : '' }
                        <br/>
                    </div>
                    </div>

                </div>
            </div>

            <div class="f-col s-even">
                <div className="form auto s-fill f-col">
                    <h3>Test Data Sources</h3>

                    <div class="mb-4">
                        <span class="btn alt-4" onClick={ loadFiles }>
                            <i class="fa fa-upload me-1"></i>
                            <span>Load File(s)</span>
                        </span>

                        <span class="btn" onClick={ clearFiles }>
                            <i class="fa fa-trash me-1"></i>
                            <span>Clear</span>
                        </span>
                    </div>

                    <div class="s-fill p-rel ovf-hidden">
                    <div class="p-fill ovf-auto">
                        { $files.map((model, idx) => <FileInfo model={model} idx={idx} />) }
                        {
                            $files.length == 0 ? <div class="message info">There are no files to show.</div> : ''
                        }
                        <br/>
                    </div>
                    </div>

                </div>
            </div>

        </div>

   </r-panel>
;
