
import { DataSource, watch } from 'riza';
import { authStatus } from '../../../signals';

import { ds as dsClients } from '../clients/common';
import { ds as dsPlans } from '../plans/common';

export const ds = new DataSource('instances');

watch([authStatus], (status) => {
    if (status !== authStatus.AUTH) return;
    ds.refresh('enum');
});

/* ****************** */
export const Fields = ({ updating=false }) =>
<div>

    <div className="field" data-priv="admin">
        <label>Client</label>
        <r-select dataList={ dsClients.enum } data-field="client_id" disabled={ updating } />
    </div>

    <div className="field">
        <label>Plan</label>
        <r-select dataList={ dsPlans.enum } data-field="plan_id" />
    </div>

    <div className="field">
        <label>Instance Name</label>
        <input type="text" data-field="name" />
    </div>

    <label class="checkbox">
        <input type="checkbox" data-field="is_enabled" data-default="1" />
        <span>Instance is enabled</span>
    </label>

</div>;
