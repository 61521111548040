
import { clearTableFilters, deleteRecord, navigate } from '../../../actions';
import { Pagination } from '../../../elems';
import { ds } from './common';

/* ****************** */
export default () => 
    <r-panel class="s-fill" data-route="/service/clients/list/" onPanelShown={ () => ds.refresh() }>

        <h1>Clients</h1>

        <Pagination dataSource={ ds }>
            <span class="btn alt-4" onClick={ () => navigate("/service/clients/add/") }>
                <i class="fa-solid fa-plus me-1 me-0-d"></i> <span class="d-none-d">Create Client</span>
            </span>
        </Pagination>

        <div class="d-block">
            <r-table dataSource={ ds }>
            <table>
                <thead>
                    <tr>
                        <th style:width="9rem" data-sort="created"><span>Created</span></th>
                        <th data-sort="name"><span>Client Name</span></th>
                        <th data-sort="username"><span>Username</span></th>
                        <th><span>Email</span></th>
                        <th><span>Phone</span></th>
                        <th><span>City</span></th>
                        <th><span>State</span></th>
                        <th style:width="3rem"></th>
                    </tr>
                </thead>

                <thead>
                    <tr>
                        <th></th>
                        <th><input class="input-small" type="text" data-property="filter_name" /></th>
                        <th><input class="input-small" type="text" data-property="filter_username" /></th>
                        <th><input class="input-small" type="text" data-property="filter_email" /></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style:textAlign="center">
                            <span class="btn small" onClick={ clearTableFilters }>
                                <i class="fas fa-filter-circle-xmark"></i>
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody className="x-empty">
                    <tr>
                        <td colSpan="8">There are no clients to show.</td>
                    </tr>
                </tbody>

                <tbody class="x-data" content={ (item) =>
                    <tr class="is-clickable no-underline" onClick={ () => navigate("/service/clients/update/"+item.id) }>
                        <td>{item.s_created}</td>
                        <td>{item.name}</td>
                        <td>
                            { item.is_authorized ? <i class="fas fa-check me-1 text-green"></i> : <i class="fas fa-times me-1 text-red"></i> }
                            { item.username }
                        </td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.city}</td>
                        <td>{item.state}</td>
                        <td>
                        </td>
                    </tr>
                }>
                </tbody>
            </table>
            </r-table>
        </div>

    </r-panel>
;
