
import { clearTableFilters, deleteRecord, navigate } from '../../../actions';
import { Pagination } from '../../../elems';
import { ds } from './common';

/* ****************** */
export default () => 
    <r-panel data-route="/service/tokens/list" onPanelShown={ () => ds.refresh() }>

        <h1>API Access Tokens</h1>

        <Pagination dataSource={ ds }>
            <span class="btn alt-4" onClick={ () => navigate("/service/tokens/add/") }>
                <i class="fa-solid fa-plus me-1"></i> Create Token
            </span>
            <span class="btn alt-5" onClick={ () => navigate("/service/tokens/help/") }>
                <i class="fa-solid fa-link me-1"></i> Integration Docs
            </span>
        </Pagination>

        <r-table dataSource={ ds }>
        <table>
            <thead>
                <tr>
                    <th style:width="9rem" data-sort="created"><span>Created</span></th>
                    <th data-sort="instance"><span>Instance</span></th>
                    <th data-sort="name"><span>Token Name</span></th>
                    <th data-sort="token_usage"><span>Current Usage</span></th>
                    <th style:width="8rem"></th>
                </tr>
            </thead>

            <thead>
                <tr>
                    <th></th>
                    <th><input class="input-small" type="text" data-property="filter_instance" /></th>
                    <th><input class="input-small" type="text" data-property="filter_name" /></th>
                    <th></th>
                    <th style:textAlign="center">
                        <span class="btn small" onClick={ clearTableFilters }>
                            <i class="fa-solid fa-filter-circle-xmark"></i>
                        </span>
                    </th>
                </tr>
            </thead>

            <tbody className="x-empty">
                <tr>
                    <td colSpan="5">There are no tokens to show.</td>
                </tr>
            </tbody>

            <tbody class="x-data" content={ (item) =>
                <tr class="is-clickable no-underline" onClick={ () => navigate("/service/tokens/update/"+item.id) }>
                    <td>{item.s_created}</td>
                    <td>{item.instance}</td>
                    <td>
                        { item.is_authorized ? <i class="fas fa-check me-1 text-green"></i> : <i class="fas fa-times me-1 text-red"></i> }
                        { item.name }
                    </td>
                    <td>{item.token_usage}</td>
                    <td>
                        <span class="btn alt-5 small" onClick={ (evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            let el = evt.currentTarget;
                            navigator.clipboard.writeText(item.token);
                            el.querySelector('span').innerText = 'Copied!';
                            el.querySelector('i').className = 'fas fa-check me-1';
                            setTimeout(() => {
                                el.querySelector('span').innerText = 'Copy Token';
                                el.querySelector('i').className = 'far fa-copy me-1';
                            }, 1500);
                        } }>
                            <i class="far fa-copy me-1"></i>
                            <span>Copy Token</span>
                        </span>
                    </td>
                </tr>
            }>
            </tbody>
        </table>
        </r-table>

    </r-panel>
;
