
import { clearTableFilters, deleteRecord, navigate } from '../../../actions';
import { Pagination } from '../../../elems';
import { ds } from './common';

/* ****************** */
export default () => 
    <r-panel class="s-fill" data-route="/service/plans/list/" onPanelShown={ () => ds.refresh() }>

        <h1>Billing Plans</h1>

        <Pagination dataSource={ ds }>
            <span class="btn alt-4" onClick={ () => navigate("/service/plans/add/") }>
                <i class="fa-solid fa-plus me-1 me-0-d"></i> <span class="d-none-d">Create Plan</span>
            </span>
        </Pagination>

        <div class="d-block">
            <r-table dataSource={ ds }>
            <table>
                <thead>
                    <tr>
                        <th style:width="9rem" data-sort="created"><span>Created</span></th>
                        <th data-sort="name"><span>Plan Name</span></th>
                        <th data-sort="billing_document_price"><span>Document Price</span></th>
                        <th data-sort="billing_document_min"><span>Minimum Documents</span></th>
                        <th data-sort="billing_period"><span>Period</span></th>
                        <th data-sort="billing_price"><span>Price</span></th>
                        <th style:width="3rem"></th>
                    </tr>
                </thead>

                <thead>
                    <tr>
                        <th></th>
                        <th><input class="input-small" type="text" data-property="filter_name" /></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style:textAlign="center">
                            <span class="btn small" onClick={ clearTableFilters }>
                                <i class="fas fa-filter-circle-xmark"></i>
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody className="x-empty">
                    <tr>
                        <td colSpan="7">There are no plans to show.</td>
                    </tr>
                </tbody>

                <tbody class="x-data" content={ (item) =>
                    <tr class="is-clickable no-underline" onClick={ () => navigate("/service/plans/update/"+item.id) }>
                        <td>{item.s_created}</td>
                        <td>{item.name}</td>
                        <td>{parseFloat(item.billing_document_price) ? 'US$ ' + item.billing_document_price : '-'}</td>
                        <td>{item.billing_document_min ? item.billing_document_min : '-'}</td>
                        <td>{item.billing_period ? item.billing_period + ' Mo.' : '-'}</td>
                        <td>{~~item.billing_price ? 'US$ ' + item.billing_price : '-'}</td>
                        <td></td>
                    </tr>
                }>
                </tbody>
            </table>
            </r-table>
        </div>

    </r-panel>
;
