
import { clearTableFilters, deleteRecord, navigate } from '../../actions';
import { Pagination } from '../../elems';
import { ds } from './common';

/* ****************** */
export default () => 
    <r-panel data-route="/extractors/list" onPanelShown={ () => ds.refresh() }>

        <h1>Data Extractors</h1>

        <Pagination dataSource={ ds }>
            <span class="btn alt-4" onClick={ () => navigate("/extractors/add/") }>
                <i class="fa-solid fa-plus me-1"></i> New Extractor
            </span>
        </Pagination>

        <div data-priv="admin">
        <r-table dataSource={ ds }>
        <table>
            <thead>
                <tr>
                    <th style:width="9rem" data-sort="created"><span>Created</span></th>
                    <th data-sort="client"><span>Client</span></th>
                    <th data-sort="instance"><span>Instance</span></th>
                    <th data-sort="name"><span>Extractor Name</span></th>
                    <th data-sort="current_usage"><span>Current Usage</span></th>
                    <th style:width="8rem"></th>
                </tr>
            </thead>

            <thead>
                <tr>
                    <th></th>
                    <th><input class="input-small" type="text" data-property="filter_client" /></th>
                    <th><input class="input-small" type="text" data-property="filter_instance" /></th>
                    <th><input class="input-small" type="text" data-property="filter_name" /></th>
                    <th></th>
                    <th style:textAlign="center">
                        <span class="btn small" onClick={ clearTableFilters }>
                            <i class="fa-solid fa-filter-circle-xmark"></i>
                        </span>
                    </th>
                </tr>
            </thead>

            <tbody className="x-empty">
                <tr>
                    <td colSpan="6">There are no extractors to show.</td>
                </tr>
            </tbody>

            <tbody class="x-data" content={ (item) =>
                <tr class="is-clickable no-underline" onClick={ () => navigate("/extractors/update/"+item.id) }>
                    <td>{item.s_created}</td>
                    <td>{item.client}</td>
                    <td>{item.instance}</td>
                    <td>{ item.name }</td>
                    <td>{item.current_usage}</td>
                    <td style:textAlign="center">
                        <span class="btn alt-5 small" onClick={ (evt) => navigate('/extractors/configure/'+item.id, evt) }>
                            <i class="fas fa-cog me-1"></i>
                            <span>Configure</span>
                        </span>
                    </td>
                </tr>
            }>
            </tbody>
        </table>
        </r-table>
        </div>


        <div data-priv="client|user">
        <r-table dataSource={ ds }>
        <table>
            <thead>
                <tr>
                    <th style:width="9rem" data-sort="created"><span>Created</span></th>
                    <th data-sort="instance"><span>Instance</span></th>
                    <th data-sort="name"><span>Extractor Name</span></th>
                    <th data-sort="current_usage"><span>Current Usage</span></th>
                    <th style:width="8rem"></th>
                </tr>
            </thead>

            <thead>
                <tr>
                    <th></th>
                    <th><input class="input-small" type="text" data-property="filter_instance" /></th>
                    <th><input class="input-small" type="text" data-property="filter_name" /></th>
                    <th></th>
                    <th style:textAlign="center">
                        <span class="btn small" onClick={ clearTableFilters }>
                            <i class="fa-solid fa-filter-circle-xmark"></i>
                        </span>
                    </th>
                </tr>
            </thead>

            <tbody className="x-empty">
                <tr>
                    <td colSpan="5">There are no extractors to show.</td>
                </tr>
            </tbody>

            <tbody class="x-data" content={ (item) =>
                <tr class="is-clickable no-underline" onClick={ () => navigate("/extractors/update/"+item.id) }>
                    <td>{item.s_created}</td>
                    <td>{item.instance}</td>
                    <td>{ item.name }</td>
                    <td>{item.current_usage}</td>
                    <td style:textAlign="center">
                        <span class="btn alt-5 small" onClick={ (evt) => navigate('/extractors/configure/'+item.id, evt) }>
                            <i class="fas fa-cog me-1"></i>
                            <span>Configure</span>
                        </span>
                    </td>
                </tr>
            }>
            </tbody>
        </table>
        </r-table>
        </div>

    </r-panel>
;
