
import { Router, watch } from 'riza';
import { navigate, checkAuth } from './actions';
import { authStatus } from './signals';

let formLogin;
let formRecover;
let formOTP;

function init()
{
    document.body.classList.add('anim-ended');
    Router.refresh();
    Router.initialLocation = Router.location;
    navigate('/');
}

function checkResponse (r)
{
    if (!r.otp)
        return checkAuth();

    formOTP.reset();
    formOTP.model.set(formLogin.model.get());
    navigate('/otp/');
}

watch([authStatus], (_authStatus) =>
{
    if (_authStatus !== authStatus.NOT_AUTH)
        return;

    document.body.classList.remove('anim-ended');

    if (Router.initialLocation != '/login/' && Router.initialLocation != '/recover/')
        navigate('/login/');
    else
        navigate(Router.initialLocation);
});

export default () =>
    <r-panel class="s-fill f-row flex-justify-center" onRootReady={ init }>

        <div class="s-static flex-middle form f-col s-12 s-6-md s-4-lg s-3-xl">

            <div class="s-static" style:textAlign="center">
                <br/><br/><br/>

                <span style:display="inline-block">
                    <img src="img/logo-login.png" />
                </span>

                <br/><br/><br/>
            </div>

            <r-panel style:textAlign="center" style:marginTop="2rem" data-route="/$">
                <img src="img/main-spinner.gif" />
            </r-panel>

            <div class="s-fill multiplex">

                {/* **************** LOGIN **************** */}
                <r-panel style:padding="0 1rem" data-anim="fade-in" data-route="/login/" onPanelShown={ () => formLogin.reset() }>
                <r-form data-form-action="auth.login" onFormSuccess={ checkResponse } onCreated={ f=>formLogin=f }>

                    <div class="field">
                        <label>Username</label>
                        <input type="text" data-field="username" autocomplete="off" />
                    </div>

                    <div class="field">
                        <label>Password</label>
                        <input type="password" data-field="password" />
                    </div>

                    <br/>
                    <div className="message error"></div>

                    <button type="submit">
                        <i class="fa fa-sign-in" style:marginRight="0.5rem"></i>
                        Login
                    </button>

                    <div style:textAlign="right" style:marginTop="0.5rem" style:fontSize="0.9rem">
                        <a href="#/recover/">
                            <span>Forgot Password?</span>
                        </a>
                    </div>


                </r-form>
                </r-panel>

                {/* **************** RECOVER **************** */}
                <r-panel style:padding="0 1rem" data-anim="fade-in" data-route="/recover/" onPanelShown={ () => formRecover.reset() }>
                <r-form data-form-action="auth.recover" onCreated={ f=>formRecover=f }>

                    <div>
                        Please provide the email address you used to register with us in the box below.
                    </div>

                    <br/><br/>

                    <div class="field">
                        <label>Email Address</label>
                        <input type="email" data-field="email" autocomplete="off" />
                    </div>

                    <br/>
                    <div className="message error"></div>
                    <div className="message success"></div>

                    <button type="submit">
                        <i class="fa-regular fa-paper-plane" style:marginRight="0.5rem"></i>
                        Send Login Link
                    </button>

                    <div style:textAlign="right" style:marginTop="0.5rem" style:fontSize="0.9rem">
                        <a href="#/login/">
                            <span>Have an account?</span>
                        </a>
                    </div>

                </r-form>
                </r-panel>

                {/* **************** OTP **************** */}
                <r-panel style:padding="0 1rem" data-anim="fade-in" data-route="/otp/">
                <r-form data-form-action="auth.login" onFormSuccess={ checkAuth } onCreated={ f=>formOTP=f }>

                    <div class="field">
                        <label>Username</label>
                        <input type="text" data-field="username" autocomplete="off" disabled />
                    </div>

                    <div class="field">
                        <label>Password</label>
                        <input type="password" data-field="password" disabled />
                    </div>

                    <div class="field">
                        <label>2FA Code</label>
                        <input type="number" data-field="code" autocomplete="off" style:textAlign="center" />
                    </div>

                    <br/>
                    <div className="message error"></div>
                    <div className="message success"></div>

                    <button type="submit">
                        <i class="fa fa-sign-in" style:marginRight="0.5rem"></i>
                        Login
                    </button>

                    <div style:textAlign="right" style:marginTop="0.5rem" style:fontSize="0.9rem">
                        <a href="#/login/">
                            <span>Sign-in with different account</span>
                        </a>
                    </div>

                </r-form>
                </r-panel>
            </div>

        </div>

    </r-panel>
;