
import { goBack } from '../../../actions';
import { Fields } from './common';

let form;

/* ****************** */
export default () => 
    <r-panel class="s-fill" data-route="/service/instances/add" onPanelShown={ () => form.reset() }>

        <h1>Service Instances</h1>

        <div className="buttons">
            <div class="button-container me-2">
            <div class="button-group">
                <span class="btn" onClick={ goBack }>
                    <i class="fa-solid fa-arrow-left-long me-1"></i>
                    <span class="d-none-d">Go Back</span>
                </span>
            </div>
            </div>
        </div>

        <r-form class="form left" data-form-action="instances.add" onFormSuccess={ goBack } onCreated={ f=>form=f }>

            <h3>New Instance</h3>

            <Fields />
            <br/><br/>

            <div className="message error"></div>
            <div className="message success"></div>

            <button class="inline" type="submit">
                <i class="fa-solid fa-check me-0 me-1-md"></i>
                <span>Create Instance</span>
            </button>

            <button class="inline gray" type="button" onClick={ goBack }>
                <i class="fa-solid fa-times me-0 me-1-md"></i>
                <span class="d-none d-inline-md">Cancel</span>
            </button>

       </r-form>

   </r-panel>
;
