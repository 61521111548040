
import { authStatus } from './signals';
import { checkAuth } from './actions';

import PublicArea from './public';
import PrivateArea from './private';

import "./css/main.css"
import "./css/xui.css"

const panel = { };
panel[authStatus.UNDEF] = panel[authStatus.NOT_AUTH] = <PublicArea />;
panel[authStatus.AUTH] = <PrivateArea />;

function init() {
    checkAuth();
}

export default () =>
    <r-panel class="s-fill f-row" onRootReady={ init }>
        { panel[$authStatus] }
    </r-panel>
;
