
import List from './list';
import Add from './add';
import Update from './update';
import Help from './help';

export default () =>
    <r-panel data-route="/service/tokens/">

        <List />
        <Add />
        <Update />
        <Help />

    </r-panel>
;
