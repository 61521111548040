
import { expr } from 'riza';

/* ****************** */
export function MultiSelect ({ list, ...props })
{
    let val = [];
    const el = <div {...props}>
            {
                expr([list], (list) => {
                    return list.map(o => 
                        <label class="checkbox inline-33">
                            <input type="checkbox" value={ o.value } checked={ val.indexOf(o.value+'') !== -1 } />
                            <span>{ o.label }</span>
                        </label>
                    );
                })
            }
        </div>;

    el.type = 'field';

    el.getValue = function () {
        return [...this.querySelectorAll('input')].filter(i => i.checked).map(i => i.value).join(',');
    };

    el.setValue = function (value) {
        value = (value ?? '').split(',');
        val = value;

        this.querySelectorAll('input').forEach(i => {
            i.checked = value.indexOf(i.value+'') !== -1;
        });
    }; 

    return el;
}

/* ****************** */
export function Pagination ({ dataSource }, children)
{
    return <r-paginator class="buttons" dataSource={ dataSource }>

        {
            !children.length ? "" :
            <div class="button-container me-2">
            <div class="button-group">
                {children}
            </div>
            </div>
        }

        <div class="button-container">
        <div class="button-group">
            <span class="btn btn-br" data-action="refresh"><i class="fa-solid fa-arrows-rotate"></i> Refresh</span>
            <span title="First Page" class="btn" data-action="firstPage"><i class="fa-solid fa-backward-step"></i></span>
            <span title="Previous" class="btn" data-action="prevPage"><i class="fa-solid fa-angle-left"></i></span>
            <span class="btn alt-3 no-pointer-events" data-watch="count" >[offsetStart] to [offsetEnd] of [count]</span>
            <span title="Next" class="btn" data-action="nextPage"><i class="fa-solid fa-angle-right"></i></span>
            <span title="Last Page" class="btn" data-action="lastPage"><i class="fa-solid fa-forward-step"></i></span>
        </div>
        </div>

    </r-paginator>
};
