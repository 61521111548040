
import { DataSource } from 'riza';

import { ds as dsClients } from '../clients/common';
import { ds as dsPlans } from '../plans/common';

export const ds = new DataSource('users');

/* ****************** */
export const Fields = ({ updating=false }) =>
<div>

    <div className="field" data-priv="admin">
        <label>Client</label>
        <r-select dataList={ dsClients.enum } data-field="client_id" disabled={ updating } />
    </div>

    <div class="f-row g-2">
        <div className="field s-12 s-6-md">
            <label>Username</label>
            <input type="text" data-field="username" />
        </div>
        <div className="field s-12 s-6-md">
            <label>Password</label>
            <input type="password" data-field="password" />
        </div>
    </div>

    <div className="field" data-priv="admin">
        <label>User Type</label>
        <select data-field="privilege_id">
            <option value="7000">Administrator</option>
            <option value="6000">Client</option>
            <option value="5000">Regular User</option>
        </select>
    </div>

    <div className="field">
        <label>Full Name</label>
        <input type="text" data-field="name" />
    </div>
    <div class="f-row g-2">
        <div className="field s-12 s-6-md">
            <label>Email Address</label>
            <input type="email" data-field="email" />
        </div>
        <div className="field s-12 s-6-md">
            <label>Phone Number</label>
            <input type="text" data-field="phone" />
        </div>
    </div>

    <label class="checkbox">
        <input type="checkbox" data-field="is_enabled" data-default="1" />
        <span>Authorized to Login</span>
    </label>

</div>;
