
import { DataSource, watch } from 'riza';
import { authStatus } from '../../../signals';

export const ds = new DataSource('plans', { includeEnum: true });

watch([authStatus], (status) => {
    if (status !== authStatus.AUTH) return;
    ds.refresh('enum');
});

/* ****************** */
export const Fields = ({ updating=false }) =>
<div>

    <div className="field">
        <label>Plan Name</label>
        <input type="text" data-field="name" />
    </div>

    <label class="checkbox">
        <input type="checkbox" data-field="is_public" data-default="1" />
        <span>Plan is publicly visible</span>
    </label>

    <span class="legend">Plan Billing Details</span>
    <div className="f-row" style:gap="1rem">
        <div className="field s-even">
            <label>Billing Period <span class="d-none d-inline-md">(Months)</span><span class="d-inline d-none-md">(Mo.)</span></label>
            <input type="number" data-field="billing_period" data-default="1" />
        </div>
        <div className="field s-even">
            <label>Billing Price (USD)</label>
            <input type="number" data-field="billing_price" />
        </div>
    </div>
    <div className="f-row" style:gap="1rem">
        <div className="field s-even">
            <label>Document Price (USD)</label>
            <input type="number" data-field="billing_document_price" />
        </div>
        <div className="field s-even">
            <label>Minimum Documents</label>
            <input type="number" data-field="billing_document_min" data-default="0" />
        </div>
    </div>
    <label class="checkbox">
        <input type="checkbox" data-field="is_billable" data-default="1" />
        <span>Plan is billable</span>
    </label>

    <span class="legend">Plan Limits</span>
    <div className="f-row" style:gap="1rem">
        <div className="field s-even">
            <label>Users</label>
            <input type="number" data-field="limit_users" data-default="0" />
        </div>
        <div className="field s-even">
            <label><span class="d-none d-inline-md">Access</span> Tokens</label>
            <input type="number" data-field="limit_tokens" data-default="0" />
        </div>
        <div className="field s-even">
            <label>API Calls</label>
            <input type="number" data-field="limit_calls" data-default="0" />
        </div>
    </div>

    <small>Note: Setting the limit to 0 will disable limit checking.</small>

</div>;
