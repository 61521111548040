
import { deleteRecord, loadForm, goBack } from '../../../actions';
import { Fields, ds } from './common';
import { ds as dsClients } from '../clients/common';
import { signal } from 'riza';
import { MultiSelect } from '../../../elems';

let instanceList = signal([]);
let form;

function init({ id })
{
    loadForm({ id }, ds, form, goBack, (r) => {
        dsClients.fetchData({ f: '.instances', id: r.client_id }).then(r2 => {
            if (r2.error) {
                alert(r2.error);
                return goBack();
            }
            instanceList.set(r2.data);
        });
    });
}

// *********************************************
export default () => 
    <r-panel class="s-fill" data-route="/service/users/instances/:id" onPanelShown={ init }>

        <h1>Users</h1>

        <div className="buttons">
            <div class="button-container me-2">
            <div class="button-group">
                <span class="btn" onClick={ goBack }>
                    <i class="fa-solid fa-arrow-left-long me-1"></i>
                    <span class="d-none-d">Go Back</span>
                </span>
            </div>
            </div>
        </div>

        <r-form class="form left" data-form-action="users.update-instances" onFormSuccess={ goBack } onCreated={ f=>form=f }>

            <h3>Instances Allowed for User</h3>

            <input type="hidden" data-field="id" />

            <div className="field" data-priv="admin">
                <label>Client</label>
                <r-select dataList={ dsClients.enum } data-field="client_id" disabled />
            </div>

            <div className="field">
                <label>Full Name</label>
                <input type="text" data-field="name" disabled />
            </div>

            <br/>

            <label>Allowed Instances to Access:</label>
            <br/><br/>
            <MultiSelect list={ instanceList } data-field="instance_id" />

            <br/>
            <br/>

            <div className="message error"></div>
            <div className="message success"></div>

            <button class="inline" type="submit">
                <i class="fas fa-save me-1"></i>
                <span>Save Changes</span>
            </button>

            <button class="inline gray" type="button" onClick={ goBack }>
                <i class="fa-solid fa-times me-0 me-1-md"></i>
                <span class="d-none d-inline-md">Cancel</span>
            </button>

            <button class="inline red" type="button" style:float="right" onClick={ () => deleteRecord(form.model.get('id'), ds) }>
                <i class="fas fa-trash-can me-0 me-1-md"></i>
                <span class="d-none d-inline-md">Delete</span>
            </button>

       </r-form>

   </r-panel>
;
