
import { signal, watch } from 'riza';

export const userData = signal({ });

export const authStatus = signal(0);
authStatus.UNDEF = 0;
authStatus.AUTH = 1;
authStatus.NOT_AUTH = 2;
authStatus.INITIAL = 3;

watch([userData], (userData) =>
{
    if (!userData || !userData.privileges)
        return;

    queueMicrotask(() => {
        checkPrivileges(document.body);
    });
});


export function checkPrivileges (root)
{
    if (!userData.value || !userData.value.privileges) return;
    let activePrivileges = userData.value.privileges;
    root.querySelectorAll('[data-priv]').forEach(e => {
        if (!e.dataset.priv.split('|').some(i => activePrivileges.includes(i)))
            e.remove();
    });
}
