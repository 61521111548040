
import { DataSource, watch } from 'riza';
import { authStatus } from '../../../signals';

export const ds = new DataSource('clients', { includeEnum: true });

watch([authStatus], (status) => {
    if (status !== authStatus.AUTH) return;
    ds.refresh('enum');
});

/* ****************** */
export const Fields = ({ updating=false }) =>
<div>

    <div className="field">
        <label>Client Type</label>
        <select data-field="is_business" data-default="0">
            <option value="0">Natural Person</option>
            <option value="1">Legal Entity</option>
        </select>
    </div>

    <div className="f-row g-2" data-visible="[eq [is_business] 0]">
        <div className="field s-4h">
            <label>First Name</label>
            <input type="text" data-field="first_name" />
        </div>
        <div className="field s-3">
            <label>Middle <span class="d-none d-inline-md">Name</span></label>
            <input type="text" data-field="middle_name" />
        </div>
        <div className="field s-4h">
            <label>Last Name</label>
            <input type="text" data-field="last_name" />
        </div>
    </div>
    <div className="f-row g-2" data-visible="[eq [is_business] 1]">
        <div className="field s-even">
            <label>Business Name</label>
            <input type="text" data-field="business_name" />
        </div>
    </div>

    <div className="f-row g-2">
        <div className="field s-12 s-6-md">
            <label>Username</label>
            <input type="text" data-field="username" disabled={updating} />
        </div>
        <div className="field s-12 s-6-md">
            <label>Password</label>
            <input type="password" data-field="password" />
        </div>
    </div>

    <span class="legend">Contact Details</span>
    <div className="f-row g-2">
        <div className="field s-12 s-6-md">
            <label>Email</label>
            <input type="email" data-field="email" />
        </div>
        <div className="field s-12 s-6-md">
            <label>Phone</label>
            <input type="tel" data-field="phone" />
        </div>
    </div>

    <div className="f-row g-2">
        <div className="field s-even">
            <label>Street Address</label>
            <input type="text" data-field="address1" />
        </div>
        <div className="field s-even">
            <label>&nbsp;</label>
            <input type="text" data-field="address2" />
        </div>
    </div>

    <div className="f-row g-2">
        <div className="field s-even">
            <label>City</label>
            <input type="text" data-field="city" />
        </div>
        <div className="field s-even">
            <label>State</label>
            <input type="text" data-field="state" />
        </div>
        <div className="field s-even">
            <label>Zip</label>
            <input type="text" data-field="zip" />
        </div>
    </div>

</div>;
