
import { DataSource } from 'riza';
import { ds as dsInstances } from '../instances/common';

export const ds = new DataSource('account.tokens');

/* ****************** */
export const Fields = ({ updating=false }) =>
<div>

    <div className="field">
        <label>Instance</label>
        <r-select dataList={ dsInstances.enum } data-field="instance_id" disabled={updating} />
    </div>

    <div className="field">
        <label>Token Name</label>
        <input type="text" data-field="name" />
    </div>

    <div className="field" class:is-hidden={ !updating }>
        <label>Access Token</label>
        <input type="text" data-field="token" onClick={ ({currentTarget}) => currentTarget.select() } />
    </div>

    <label class="checkbox">
        <input type="checkbox" data-field="is_authorized" data-default="1" />
        <span>Token is enabled</span>
    </label>

</div>;
