
import { clearTableFilters, deleteRecord, navigate } from '../../../actions';
import { Pagination } from '../../../elems';
import { ds } from './common';

/* ****************** */
export default () => 
    <r-panel class="s-fill" data-route="/service/users/list/" onPanelShown={ () => ds.refresh() }>

        <h1>Users</h1>

        <Pagination dataSource={ ds }>
            <span class="btn alt-4" onClick={ () => navigate("/service/users/add/") }>
                <i class="fa-solid fa-plus me-1 me-0-d"></i> <span class="d-none-d">Create User</span>
            </span>
        </Pagination>

        <div data-priv="admin" class="d-block">
            <r-table dataSource={ ds }>
            <table>
                <thead>
                    <tr>
                        <th style:width="9rem" data-sort="created"><span>Created</span></th>
                        <th data-sort="client"><span>Client</span></th>
                        <th data-sort="username"><span>Username</span></th>
                        <th data-sort="privilege"><span>User Type</span></th>
                        <th data-sort="name"><span>Name</span></th>
                        <th data-sort="phone"><span>Phone</span></th>
                        <th data-sort="email"><span>Email</span></th>
                        <th data-sort="instance"><span>Instancia</span></th>
                        <th style:width="8rem"></th>
                    </tr>
                </thead>

                <thead>
                    <tr>
                        <th></th>
                        <th><input class="input-small" type="text" data-property="filter_client" /></th>
                        <th><input class="input-small" type="text" data-property="filter_username" /></th>
                        <th>
                            <select class="input-small" data-property="filter_privilege_id">
                                <option value="">All</option>
                                <option value="7000">Administrator</option>
                                <option value="6000">Client</option>
                                <option value="5000">Regular User</option>
                            </select>
                        </th>
                        <th><input class="input-small" type="text" data-property="filter_name" /></th>
                        <th><input class="input-small" type="text" data-property="filter_phone" /></th>
                        <th><input class="input-small" type="text" data-property="filter_email" /></th>
                        <th><input class="input-small" type="text" data-property="filter_instance" /></th>
                        <th style:textAlign="center">
                            <span class="btn small" onClick={ clearTableFilters }>
                                <i class="fas fa-filter-circle-xmark"></i>
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody className="x-empty">
                    <tr>
                        <td colSpan="9">There are no users to show.</td>
                    </tr>
                </tbody>

                <tbody class="x-data" content={ (item) =>
                    <tr class="is-clickable no-underline" onClick={ () => navigate("/service/users/update/"+item.id) }>
                        <td>{item.s_created}</td>
                        <td>{item.client}</td>
                        <td>
                            { item.is_authorized ? <i class="fas fa-check me-1 text-green"></i> : <i class="fas fa-times me-1 text-red"></i> }
                            { item.username }
                        </td>
                        <td>{item.privilege}</td>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{item.instance}</td>
                        <td style:textAlign="center">
                            <span class="btn alt-5 small" onClick={ (evt) => navigate('/service/users/instances/'+item.id, evt) }>
                                <i class="fas fa-server me-1"></i>
                                <span>Instances</span>
                            </span>
                        </td>
                    </tr>
                }>
                </tbody>
            </table>
            </r-table>
        </div>

        <div data-priv="client" class="d-block">
            <r-table dataSource={ ds }>
            <table>
                <thead>
                    <tr>
                        <th style:width="9rem" data-sort="created"><span>Created</span></th>
                        <th data-sort="username"><span>Username</span></th>
                        <th data-sort="name"><span>Name</span></th>
                        <th data-sort="phone"><span>Phone</span></th>
                        <th data-sort="email"><span>Email</span></th>
                        <th data-sort="instance"><span>Instancia</span></th>
                        <th style:width="8rem"></th>
                    </tr>
                </thead>

                <thead>
                    <tr>
                        <th></th>
                        <th><input class="input-small" type="text" data-property="filter_username" /></th>
                        <th><input class="input-small" type="text" data-property="filter_name" /></th>
                        <th><input class="input-small" type="text" data-property="filter_phone" /></th>
                        <th><input class="input-small" type="text" data-property="filter_email" /></th>
                        <th><input class="input-small" type="text" data-property="filter_instance" /></th>
                        <th style:textAlign="center">
                            <span class="btn small" onClick={ clearTableFilters }>
                                <i class="fas fa-filter-circle-xmark"></i>
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody className="x-empty">
                    <tr>
                        <td colSpan="7">There are no users to show.</td>
                    </tr>
                </tbody>

                <tbody class="x-data" content={ (item) =>
                    <tr class="is-clickable no-underline" onClick={ () => navigate("/service/users/update/"+item.id) }>
                        <td>{item.s_created}</td>
                        <td>
                            { item.is_authorized ? <i class="fas fa-check me-1 text-green"></i> : <i class="fas fa-times me-1 text-red"></i> }
                            { item.username }
                        </td>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{item.instance}</td>
                        <td style:textAlign="center">
                            <span class="btn alt-5 small" onClick={ (evt) => navigate('/service/users/instances/'+item.id, evt) }>
                                <i class="fas fa-server me-1"></i>
                                <span>Instances</span>
                            </span>
                        </td>
                    </tr>
                }>
                </tbody>
            </table>
            </r-table>
        </div>

    </r-panel>
;
