
import { deleteRecord, loadForm, goBack } from '../../actions';
import { Fields, ds } from './common';

let form;

// *********************************************
export default () => 
    <r-panel class="s-fill" data-route="/extractors/update/:id" onPanelShown={ ({ id }) => loadForm({ id }, ds, form, goBack) }>

        <h1>Data Extractors</h1>

        <div className="buttons">
            <div class="button-container me-2">
            <div class="button-group">
                <span class="btn" onClick={ goBack }>
                    <i class="fa-solid fa-arrow-left-long me-1"></i>
                    <span class="d-none-d">Go Back</span>
                </span>
            </div>
            </div>
        </div>

        <r-form class="form left" data-form-action="extractors.update" onFormSuccess={ goBack } onCreated={ f=>form=f }>

            <h3>Update Extractor</h3>

            <input type="hidden" data-field="id" />

            <Fields updating={true} />
            <br/><br/>

            <div className="message error"></div>
            <div className="message success"></div>

            <button class="inline" type="submit">
                <i class="fas fa-save me-1"></i>
                <span>Save Changes</span>
            </button>

            <button class="inline gray" type="button" onClick={ goBack }>
                <i class="fa-solid fa-times me-0 me-1-md"></i>
                <span class="d-none d-inline-md">Cancel</span>
            </button>

            <button class="inline red" type="button" style:float="right" onClick={ () => deleteRecord(form.model.get('id'), ds) }>
                <i class="fas fa-trash-can me-0 me-1-md"></i>
                <span class="d-none d-inline-md">Delete</span>
            </button>

       </r-form>

   </r-panel>
;
